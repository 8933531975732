import React from 'react';

const Logo: React.FC = () => {
    return (
        <div className="logo">
            <div className="logo-wrapper">
                <img src="/assets/logos/Bronze-layer-logo-brown.png" alt="bronze radio" className='logo-brown' />
                <img src="/assets/logos/Bronze-layer-logo-pink.png" alt="bronze radio" className='image-hover ' />
            </div>
        </div>
    );
};

export default Logo;