import React from 'react';
export type SongInfo = {
    info?: string
    loading: boolean
}

export const MobileBanner = ({ songInfo }: { songInfo: SongInfo }) => {
    return (
        <>
            <div>
                <p className='mobile-banner-text'>
                    {songInfo.loading ? 'LOADING' : `NOW PLAYING: ${songInfo.info?.toUpperCase()}`}
                </p>
            </div>
        </>
    );
};

export default MobileBanner
