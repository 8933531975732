import React, { Dispatch, RefObject, SetStateAction, useState } from 'react';
import { Button } from 'react-bootstrap';
import MobileSubmitHeader from './MobileSubmitHeader';
import MobileScheduleHeader from './MobileScheduleHeader'
import MobilePlayer from './MobilePlayer'
import { RadioCoPlayer } from '../../App';

export type HeaderForm = { dropDownRef: RefObject<HTMLElement>, activeForm: string, setActiveForm: Dispatch<SetStateAction<string>> }

const MobileHeader = ({ dropDownRef, player, playing, setPlaying }: { dropDownRef: RefObject<HTMLElement>, player: RadioCoPlayer | null, playing: boolean, setPlaying: Dispatch<SetStateAction<boolean>> }) => {
    const [activeForm, setActiveForm] = useState<string>('')

    return (
        <div className='mobile-header'>
            <MobileSubmitHeader activeForm={activeForm} setActiveForm={setActiveForm} dropDownRef={dropDownRef} />
            <MobileScheduleHeader activeForm={activeForm} setActiveForm={setActiveForm} dropDownRef={dropDownRef} />
            <Button href="https://instagram.com/bronze.radio">IG</Button>
            <MobilePlayer player={player} setPlaying={setPlaying} playing={playing} />
        </div >
    );
};

export default MobileHeader;