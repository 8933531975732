import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { HeaderForm } from './RadioHeader';
import { UPLOAD_LINK_ENDPOINT, UPLOAD_FILE_ENDPOINT } from '../../ENDPOINTS'

type SubmitData = {
    name: string
    email: string
    uploadLink?: string
    file?: File
}

async function _sendFile(data: SubmitData) {
    if (!data.file) return

    const fileData = new FormData()
    fileData.append('upload', data.file)
    fileData.append('name', data.name)
    fileData.append('email', data.email)


    fetch(UPLOAD_FILE_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        body: fileData,
    })
        // .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Handle success (e.g., show a success uploadLink, reset form, etc.)
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle error (e.g., show an error uploadLink)
        });
}

async function _sendUploadLink(data: SubmitData) {
    fetch(UPLOAD_LINK_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(data => {
            console.log('Success:', data);
            // Handle success (e.g., show a success uploadLink, reset form, etc.)
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle error (e.g., show an error uploadLink)
        });
}

const SubmitHeader = ({ dropDownRef, activeForm, setActiveForm }: HeaderForm) => {
    const [show, setShow] = useState(false);
    const isActive = activeForm === 'submit'

    const toggleForm = () => {
        if (isActive) {
            setShow(!show)
        } else {
            setShow(true)
            setActiveForm('submit')
        }
    }

    return (
        <>
            <Button onClick={toggleForm}>SUBMIT</Button>
            {dropDownRef.current && show && isActive && ReactDOM.createPortal(<SubmitForm setShow={setShow} />, dropDownRef.current)}
        </>
    );
};

const SubmitForm = ({ setShow }: { setShow(show: boolean): void }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [file, setFile] = useState<undefined | File>()
    const [step, setStep] = useState(1)

    const submit = async (uploadLink: string) => {
        const data: SubmitData = { name, email, uploadLink, file };
        if (data.file) {
            await _sendFile(data)
        } else {
            console.log(data, 'gooba')
            await _sendUploadLink(data)
        }
        setShow(false)
    }

    const step1 = (<NameForm setName={setName} next={() => setStep(2)} />)
    const step2 = (<EmailForm setEmail={setEmail} next={() => setStep(3)} />)
    const step3 = (<FileForm setFile={setFile} next={submit} />)

    switch (step) {
        case 1:
            return step1
        case 2:
            return step2
        case 3:
            return step3
        default:
            return step1
    }
}

const NameForm = ({ setName, next }: { setName(name: string): void, next(): void }) => {
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Name is required'),
        }),
        onSubmit: values => {
            setName(values.name)
            next()
        },
    });
    return (
        <form className='submit-form' onSubmit={formik.handleSubmit}>
            <h1 className='form-header'>SUBMIT</h1>
            <div className='form-input-container'>
                <div>
                    <label>NAME</label>
                    <input id="name"
                        name="name"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.name} />
                    {formik.touched.name && formik.errors.name ? (
                        <div>{formik.errors.name}</div>
                    ) : null}
                </div>
            </div>
            <div className='form-cta-container'>
                <Button className='form-cta' type="submit" >NEXT</Button>
            </div>
        </form>
    )
}

const EmailForm = ({ setEmail, next }: { setEmail(email: string): void, next(): void }) => {
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required('email is required'),
        }),
        onSubmit: values => {
            console.log(values)
            setEmail(values.email)
            next()
        },
    });
    return (
        <form className='submit-form' onSubmit={formik.handleSubmit}>
            <h1 className='form-header'>SUBMIT</h1>
            <div className='form-input-container'>
                <div>
                    <label>EMAIL</label>
                    <input id="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email} />
                    {formik.touched.email && formik.errors.email ? (
                        <div>{formik.errors.email}</div>
                    ) : null}
                </div>
            </div>
            <div className='form-cta-container'>
                <Button className='form-cta' type="submit" >NEXT</Button>
            </div>
        </form>
    )
}

const FileForm = ({ setFile, next }: { setFile(file: File): void, next(uploadLink: string): void }) => {
    const formik = useFormik({
        initialValues: {
            uploadLink: '',
        },
        validationSchema: Yup.object({
            uploadLink: Yup.string()
                .required('Link is required'),
        }),
        onSubmit: values => {
            next(values.uploadLink)
        },
    });

    // come back to file upload later
    // const handleUpload = (evt: ChangeEvent<HTMLInputElement>) => {
    //     const files = evt.target.files
    //     if (files?.length) {
    //         setFile(files[0])
    //     }
    // }

    return (
        <form className='submit-form' onSubmit={formik.handleSubmit}>
            <h1 className='form-header'>SUBMIT</h1>
            <div className='form-input-container'>
                {/* <div>
                    <label>CHOOSE FILE</label>
                    <input id="uploadLink"
                        name="uploadLink"
                        type="file"
                        accept="image/png, image/jpeg audio/*"
                        onChange={handleUpload}
                    />
                </div> */}
                <div>
                    <label>URL</label>
                    <input id="uploadLink"
                        name="uploadLink"
                        type="url"
                        onChange={formik.handleChange}
                        value={formik.values.uploadLink} />
                    {formik.touched.uploadLink && formik.errors.uploadLink ? (
                        <div>{formik.errors.uploadLink}</div>
                    ) : null}
                </div>
            </div>
            <div className='form-cta-container'>
                <Button className='form-cta' type="submit" >SUBMIT</Button>
            </div>
        </form>
    )
}

export default SubmitHeader;