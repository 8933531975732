import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { HeaderForm } from './RadioHeader';
import { CONTACT_US_ENDPOINT } from '../../ENDPOINTS'
import * as Yup from 'yup';

const ContactHeader = ({ dropDownRef, activeForm, setActiveForm }: HeaderForm) => {
    const [show, setShow] = useState(false);
    const isActive = activeForm === 'contact'

    const toggleForm = () => {
        if (isActive) {
            setShow(!show)
        } else {
            setShow(true)
            setActiveForm('contact')
        }
    }

    return (
        <>
            <Button onClick={toggleForm}>CONTACT</Button>
            {dropDownRef.current && show && isActive && ReactDOM.createPortal(<ContactForm setShow={setShow} />, dropDownRef.current)}
        </>
    );
};

const ContactForm = ({ setShow }: { setShow(show: boolean): void }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [step, setStep] = useState(1)

    const submit = (message: string) => {
        const data = { name, email, message };

        setShow(false)
        fetch(CONTACT_US_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const step1 = (<NameForm setName={setName} next={() => setStep(2)} />)
    const step2 = (<EmailForm setEmail={setEmail} next={() => setStep(3)} />)
    const step3 = (<MessageForm next={submit} />)

    switch (step) {
        case 1:
            return step1
        case 2:
            return step2
        case 3:
            return step3
        default:
            return step1
    }
}

const NameForm = ({ setName, next }: { setName(name: string): void, next(): void }) => {
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Name is required'),
        }),
        onSubmit: values => {
            setName(values.name)
            next()
        },
    });
    return (
        <form className='submit-form' onSubmit={formik.handleSubmit}>
            <h1 className='form-header'>CONTACT</h1>
            <div className='form-input-container'>
                <div>
                    <label>NAME</label>
                    <input id="name"
                        name="name"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div>{formik.errors.name}</div>
                    ) : null}
                </div>
            </div>
            <div className='form-cta-container'>
                <Button className='form-cta' type="submit" >NEXT</Button>
            </div>
        </form>
    )
}


const EmailForm = ({ setEmail, next }: { setEmail(email: string): void, next(): void }) => {
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required('Email is required'),
        }),
        onSubmit: values => {
            setEmail(values.email)
            next()
        },
    });
    return (
        <form className='submit-form' onSubmit={formik.handleSubmit}>
            <h1 className='form-header'>CONTACT</h1>
            <div className='form-input-container'>
                <div>
                    <label>EMAIL</label>
                    <input id="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email} />
                    {formik.touched.email && formik.errors.email ? (
                        <div>{formik.errors.email}</div>
                    ) : null}
                </div>
            </div>
            <div className='form-cta-container'>
                <Button className='form-cta' type="submit" >NEXT</Button>
            </div>
        </form>
    )
}

const MessageForm = ({ next }: { next(message: string): void }) => {
    const formik = useFormik({
        initialValues: {
            message: '',
        },
        validationSchema: Yup.object({
            message: Yup.string()
                .required('A message is required'),
        }),
        onSubmit: values => {
            next(values.message)
        },
    });
    return (
        <form className='submit-form' onSubmit={formik.handleSubmit}>
            <h1 className='form-header'>CONTACT</h1>
            <div className='form-input-container'>
                <div>
                    <label>MESSAGE</label>
                    <input id="message"
                        name="message"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.message} />
                    {formik.touched.message && formik.errors.message ? (
                        <div>{formik.errors.message}</div>
                    ) : null}
                </div>
            </div>
            <div className='form-cta-container'>
                <Button className='form-cta' type="submit" >SUBMIT</Button>
            </div>
        </form>
    )
}

export default ContactHeader;