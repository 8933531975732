import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';
import { HeaderForm } from './RadioHeader';
import { SCHEDULE_ENDPOINT } from '../../ENDPOINTS'

type Playlist = {
    name: string
    title: string
    artist: string
}

type Event = {
    start: Date
    end: Date
    playlist: Playlist
}

type DisplayEvent = {
    start: Date
    end: Date
    playlist: Playlist
    displayString: string
}

const EVENT_DISPLAY: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false }
const CURRENT_TIME_DISPLAY: Intl.DateTimeFormatOptions = { timeZoneName: 'short', hour12: false }

const ScheduleHeader = ({ dropDownRef, activeForm, setActiveForm }: HeaderForm) => {
    const [show, setShow] = useState(false);
    const isActive = activeForm === 'schedule'

    const toggleForm = () => {
        if (isActive) {
            setShow(!show)
        } else {
            setShow(true)
            setActiveForm('schedule')
        }
    }

    return (
        <>
            <Button onClick={toggleForm}>SCHEDULE</Button>
            {dropDownRef.current && show && isActive && ReactDOM.createPortal(<Schedule setShow={setShow} />, dropDownRef.current)}
        </>
    );
};

const Schedule = ({ setShow }: { setShow(show: boolean): void }) => {
    const [schedule, setSchedule] = useState([])
    const [time, setTime] = useState(new Date().toLocaleTimeString('en-US', CURRENT_TIME_DISPLAY))
    useEffect(() => {
        const now = new Date()
        fetch(SCHEDULE_ENDPOINT)
            .then(response => response.json())
            .then(resp => {
                let events = resp.data.filter((event: Event) => new Date(event.end) > now)?.slice(0, 3)
                events = events.map((evt: Event) => {
                    const onGoing = new Date(evt.start) < now
                    const dsp = onGoing ? 'NOW' : new Date(evt.start)?.toLocaleTimeString([], EVENT_DISPLAY)
                    return { ...evt, displayString: dsp }
                })
                setSchedule(events)
                // Handle success (e.g., show a success message, reset form, etc.)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSchedule([])
                // Handle error (e.g., show an error message)
            });
    }, [setSchedule])

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString('en-US', CURRENT_TIME_DISPLAY))
        }, 1000)
        return () => { clearInterval(timer) }
    }, [])

    return (
        <form className='submit-form' >
            <h1 className='form-header'>SCHEDULE</h1>
            <div className='schedule-container'>
                <h2 className='schedule-current-time'>{time}</h2>
                <div className='schedule-events-container'>
                    {schedule.map((event: DisplayEvent) => <p className='event' key={event.playlist.name}>{event.displayString} <b>{event.playlist?.name?.toUpperCase()}</b></p>)}
                </div>
            </div>
        </form>
    )
}


export default ScheduleHeader;