import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';

import MobileHeader from './MobileHeader';
import MobileBanner, { SongInfo } from './MobileBanner'
import MobileLogo from './MobileLogo';

import onAir from '../../assets/icons/pink-playing-icon.svg'
import paused from '../../assets/icons/white-paused-icon.svg'

type Callback = (event: any) => void;
export type RadioCoPlayer = {
    playToggle(): void
    event(event: string, callback: Callback): void
    load(): void
}
const CURRENT_TIME_DISPLAY: Intl.DateTimeFormatOptions = { timeZoneName: 'short', hour12: false }

function MobileApp() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [info, setInfo] = useState<SongInfo>({
        loading: true
    });
    const [player, setPlayer] = useState<RadioCoPlayer | null>(null)
    const [playing, setPlaying] = useState<boolean>(false)
    const dropDownRef = useRef<HTMLDivElement>(null);
    const [time, setTime] = useState(new Date().toLocaleTimeString('en-US', CURRENT_TIME_DISPLAY))


    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString('en-US', CURRENT_TIME_DISPLAY))
        }, 1000)
        return () => { clearInterval(timer) }
    }, [])

    useEffect(() => {
        const checkIfLoaded = () => {
            const loadedPlayer = (window as any).player as RadioCoPlayer;
            if (loadedPlayer) {
                setIsLoaded(true);
                setPlayer(loadedPlayer)
                clearInterval(intervalId);
            }
        };
        const intervalId = setInterval(checkIfLoaded, 1000); // Poll every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);


    useEffect(() => {
        if (isLoaded && player) {
            player.event('songChanged', (event) => {
                setInfo({
                    info: event.previousTrack,
                    loading: false
                })
            })
            player.load()
        };
    }, [isLoaded, player]);

    return (
        <div className="App mobile">
            <video autoPlay playsInline muted loop id="background">
                {/* <source src={Video} type="video/mp4" /> */}
                <source src="https://storage.googleapis.com/bronze-radio-background/Highway%20401%20through%20Toronto%20at%20Night.mp4" type="video/mp4" />
            </video>
            <header className="App-header">
                <MobileHeader dropDownRef={dropDownRef} player={player} setPlaying={setPlaying} playing={playing} />
                <hr className='m-2'></hr>
                <h2 className='mobile-schedule-current-time'><span><img src={playing ? onAir : paused} alt="playing" className='mx-2' /></span>{time}</h2>
                <div className='mobile-header-forms-container' ref={dropDownRef}></div>
            </header>
            <div className="app-container">
                <MobileBanner songInfo={info} />
                <hr className='m-2'></hr>
                <MobileLogo />
            </div>
        </div>
    );
}

export default MobileApp;
