import React, { Dispatch, SetStateAction } from 'react';
import { RadioCoPlayer } from '../../App';
import { Button } from 'react-bootstrap';

// flag: what to do if not loaded yet???
const MobilePlayer = ({ player, playing, setPlaying }: { player: RadioCoPlayer | null, playing: boolean, setPlaying: Dispatch<SetStateAction<boolean>> }) => {

    const togglePlaying = () => {
        if (player)
            player.playToggle()
        setPlaying(!playing)
    }

    return (
        <Button onClick={togglePlaying}>{playing ? "PAUSE" : "PLAY"}</Button>
    );
};

export default MobilePlayer;