import React from 'react';

export type SongInfo = {
    info?: string
    loading: boolean
}

export const ScrollBanner = ({ songInfo }: { songInfo: SongInfo }) => {
    const repeated: Array<string> = []
    for (let i = 0; i < 60; i++) {
        const info = songInfo.loading ? 'loading ••• ' : `NOW PLAYING: ${songInfo.info?.toUpperCase()} • `
        repeated.push(info)
    }
    return (
        <>
            <div>
                <p className="marquee">
                    <span className='info-ticker'>{repeated.join(' ')}&nbsp;</span>
                </p>
                {/* flag: weird double print with both
                
                <p className="marquee marquee2">
                    <span className='info-ticker'>{repeated.join(' ')}&nbsp;</span>
                </p> */}
            </div>
            <div className='ticker-spacer'></div>
        </>
    );
};

export default ScrollBanner
