import React, { useState } from 'react';
import { RadioCoPlayer } from '../App';

// flag: what to do if not loaded yet???
const Radio = ({ player }: { player: RadioCoPlayer | null }) => {
    const [playing, setPlaying] = useState(false)

    const togglePlaying = () => {
        if (player)
            player.playToggle()
        setPlaying(!playing)
    }

    return (
        <div className='radio-container'>
            <div>R</div>
            <div>A</div>
            <div>D</div>
            <div>I</div>
            <button className='player-container' onClick={togglePlaying}><i className={`fa-regular fa-circle-${playing ? "pause" : "play"}`}></i></button>
        </div>
    );
};

export default Radio;