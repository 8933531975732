import React, { useState, useEffect, useRef } from 'react';
// import Video from "./assets/backgrounds/example.mp4"
import './App.css';

import RadioHeader from './components/header/RadioHeader';
import ScrollBanner, { SongInfo } from './components/ScrollBanner'
import Logo from './components/Logo';
import Radio from './components/Radio';

type Callback = (event: any) => void;
export type RadioCoPlayer = {
  playToggle(): void
  event(event: string, callback: Callback): void
  load(): void
}

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [info, setInfo] = useState<SongInfo>({
    loading: true
  });
  const [player, setPlayer] = useState<RadioCoPlayer | null>(null)
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfLoaded = () => {
      const loadedPlayer = (window as any).player as RadioCoPlayer;
      if (loadedPlayer) {
        setIsLoaded(true);
        setPlayer(loadedPlayer)
        clearInterval(intervalId);
      }
    };
    const intervalId = setInterval(checkIfLoaded, 1000); // Poll every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);


  useEffect(() => {
    if (isLoaded && player) {
      player.event('songChanged', (event) => {
        setInfo({
          info: event.previousTrack,
          loading: false
        })
      })
      player.load()
    };
  }, [isLoaded, player]);

  return (
    <div className="App desktop" >
      <video autoPlay muted loop id="background">
        {/* <source src={Video} type="video/mp4" /> */}
        <source src="https://storage.googleapis.com/bronze-radio-background/Highway%20401%20through%20Toronto%20at%20Night.mp4" type="video/mp4" />
      </video>
      <header className="App-header">
        <RadioHeader dropDownRef={dropDownRef} />
        <ScrollBanner songInfo={info} />
        <div className='header-forms-container' ref={dropDownRef}></div>
      </header>
      <div className="app-container">
        <Logo />
        <Radio player={player} />
      </div>
    </div>
  );
}

export default App;
