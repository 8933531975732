import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, ModalDialog, ModalHeader, ModalBody } from 'react-bootstrap';
import { HeaderForm } from './MobileHeader';
import { SCHEDULE_ENDPOINT } from '../../ENDPOINTS'

type Playlist = {
    name: string
    title: string
    artist: string
}

type Event = {
    start: Date
    end: Date
    playlist: Playlist
}

const EVENT_DISPLAY: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false }

const ScheduleHeader = ({ dropDownRef, activeForm, setActiveForm }: HeaderForm) => {
    const [show, setShow] = useState(false);
    const isActive = activeForm === 'schedule'

    const toggleForm = () => {
        if (isActive) {
            setShow(!show)
        } else {
            setShow(true)
            setActiveForm('schedule')
        }
    }

    return (
        <>
            <Button onClick={toggleForm}>SCHEDULE</Button>
            {dropDownRef.current && show && isActive && ReactDOM.createPortal(<Schedule setShow={setShow} />, dropDownRef.current)}
        </>
    );
};

const Schedule = ({ setShow }: { setShow(show: boolean): void }) => {
    const [schedule, setSchedule] = useState([])
    useEffect(() => {

        fetch(SCHEDULE_ENDPOINT)
            .then(response => response.json())
            .then(resp => {
                const events = resp.data.slice(0, 3)
                setSchedule(events)
                // Handle success (e.g., show a success message, reset form, etc.)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSchedule([])
                // Handle error (e.g., show an error message)
            });
    }, [setSchedule])

    return (
        <div className='submit-form modal show' style={{ display: 'block', position: 'initial' }}>
            <ModalDialog>
                <ModalHeader closeButton onHide={() => setShow(false)}>
                    <Modal.Title>SCHEDULE</Modal.Title>
                </ModalHeader>

                <ModalBody>
                    <div className='mobile-schedule-events-container'>
                        {schedule.map((event: Event) => <p className='event' key={event.playlist.name}>{new Date(event.start)?.toLocaleTimeString([], EVENT_DISPLAY)} <b>{event.playlist?.name?.toUpperCase()}</b></p>)}
                    </div>
                </ModalBody>
            </ModalDialog>
        </div>
    )
}


export default ScheduleHeader;