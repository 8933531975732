import React, { Dispatch, RefObject, SetStateAction, useState } from 'react';
import { Button } from 'react-bootstrap';
import ContactHeader from './ContactHeader';
import SubmitHeader from './SubmitHeader';
import ScheduleHeader from './ScheduleHeader'

export type HeaderForm = { dropDownRef: RefObject<HTMLElement>, activeForm: string, setActiveForm: Dispatch<SetStateAction<string>> }

const RadioHeader = ({ dropDownRef }: { dropDownRef: RefObject<HTMLElement> }) => {
    const [activeForm, setActiveForm] = useState<string>('')

    return (
        <div className='header'>
            <SubmitHeader activeForm={activeForm} setActiveForm={setActiveForm} dropDownRef={dropDownRef} />
            <ScheduleHeader activeForm={activeForm} setActiveForm={setActiveForm} dropDownRef={dropDownRef} />
            <Button href="https://instagram.com/bronze.radio">INSTAGRAM</Button>
            <ContactHeader activeForm={activeForm} setActiveForm={setActiveForm} dropDownRef={dropDownRef} />
        </div >
    );
};

export default RadioHeader;